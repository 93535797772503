import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><em parentName="p">{`Toggle`}</em>{` is a control that is used to quickly switch between two possible states. Toggles are only used for these binary actions that occur immediately after the user “flips” the toggle switch. They are commonly used for “on/off” switches.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAAAsklEQVQY02NgQAb/gWj6fzBT4X8jw////xFSQDaM39HRwUA2ABlydPchOP/IkSNgesKECYQ1m+W4QRh6DIyBNXGy+TXFAjC5JUuWiLS1tUloaGiwgvj+/v6EDZRssIAwOBlYXGbEtbetm2QGk7t+/Xranj17kmGudnJyImygRIM5hGHJwKDR5criuSSVESZ36tQp5uPHjzOD2FOmTCEh0KJQuVYnUxlunrsG5y9YsACvdgBx7z+qX2G+GwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example image of on and off toggles.",
            "title": "Example image of on and off toggles.",
            "src": "/static/540937ba80090df1405ba1db8df8d72a/fb070/toggle-usage-1.png",
            "srcSet": ["/static/540937ba80090df1405ba1db8df8d72a/d6747/toggle-usage-1.png 288w", "/static/540937ba80090df1405ba1db8df8d72a/09548/toggle-usage-1.png 576w", "/static/540937ba80090df1405ba1db8df8d72a/fb070/toggle-usage-1.png 1152w", "/static/540937ba80090df1405ba1db8df8d72a/c3e47/toggle-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h4 {...{
      "id": "heading"
    }}>{`Heading`}</h4>
    <p>{`A heading may accompany a toggle to further clarify on the action the toggle will perform.`}</p>
    <h4 {...{
      "id": "labels"
    }}>{`Labels`}</h4>
    <p>{`Use labels with a toggle so the action is clear. Labels should be three words or less and appear on the side of a toggle.`}</p>
    <h4 {...{
      "id": "language"
    }}>{`Language`}</h4>
    <p>{`Use adjectives rather than verbs to describe labels and the state of the object affected.`}</p>
    <h2 {...{
      "id": "small-toggle"
    }}>{`Small toggle`}</h2>
    <p>{`Small toggles are more compact in size, therefore they can be used in use cases such as `}<a parentName="p" {...{
        "href": "/components/data-table/code"
      }}>{`data tables`}</a>{`.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.027173913043484%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAB4ElEQVQoz6WSz27aQBDG19tTL32CvktfAB6EQw+99dhThNJeeqh4g8pSSlolSqRKBFVC9GYkApg/hnABqTFg7DXYRmsz/XYrO8mhh6ojjXb8ze5vxrvDGExKadRqNaNUKrFKpcKq1SrzPI+NRiPmOA6bTqd/dZWfzWYsjmOmLcsylhsR5W70+33e6/V4o9HgKsYhjsPaW60WbzabfDgcckB1DkCjAK3X61flcvklwufwF/CH5D9Yu93+E4B+0u12W51OxxwMBnVUPkMnKjYtyzJt2zYnk4k5Ho+19297WseVKP0z9l5EUfS2IB8Ohw8QaLFYUBiGFAQBCSEIndN8Pifcp/4WgdA5+26kdX/r03a7JWVJkvwogGmavlciDka73U4CKtUKiARAPtFCIX/OLLnxPbnf75WeHI9HAuPiMfAUTq7rxoCkgKRqRfV0tVrp+Inueql77+ZagodVwMsCiLH5qDpEB6Sq5QZd/YqOcz07ZmT/cihK4mKPMgAbjzt8g/u6QYd1VL1C1TOs39Dh9WazUdpXaFdYv/iBf27d3V7fr9x6KMQ59Evo38H4VMweOmP/a+A8w8M+CBiHYkBRgCPJ0Z2xXC51jAcwon3EfeHz1/V3xnju8EOcaF3tzzm/AZX8HtFcP15uAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example image of small toggles within a data table.",
            "title": "Example image of small toggles within a data table.",
            "src": "/static/b9de91a035add5cbdc422fc59065279c/fb070/toggle-usage-2.png",
            "srcSet": ["/static/b9de91a035add5cbdc422fc59065279c/d6747/toggle-usage-2.png 288w", "/static/b9de91a035add5cbdc422fc59065279c/09548/toggle-usage-2.png 576w", "/static/b9de91a035add5cbdc422fc59065279c/fb070/toggle-usage-2.png 1152w", "/static/b9de91a035add5cbdc422fc59065279c/c3e47/toggle-usage-2.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      